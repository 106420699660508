export default {
  convertCert: {
    pageTitle: '证书格式转换',
    title: '证书格式转换',
    subtitle: '',
    btn: '转换并下载',
    placeholder: [
      '在此处粘贴您 PEM 的格式证书',
      '在此处粘贴您的 PEM 格式私钥',
      '请输入证书密码'
    ],
    summary: [
      '证书格式转换工具，不同服务器部署证书需要不同格式的SSL证书，根据当下常见服务器环境，为您提供方便快捷的证书格式转换，支持PEM、PKCS12、JKS。',
      'Bitcert.com 提供的证书工具供所有用户免费使用，Bitcert 不记录任何信息，用户在使用证书工具中、使用证书工具后产生的任何错误或造成任何损失，Bitcert 不承担任何责任。'
    ],
    form: {
      origin: '源格式',
      target: '目标格式',
      label: '密码'
    }
  }
}
