/**
 * 证书库存管理 中文版
 * 页面路由 https://bitcert.com/certhub/inventory
 */

 // Banner
 const banner = {
  title: '证书库存管理',
  summary: '让所有 SSL/TLS 证书的360度透明无死角。无论证书颁发机构如何，都可以获取跨网络使用的证书的单一视图。',
  link: 'https://app.bitcert.com/sign-up',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_inventory.svg'
}

// 证书目录说明
const manage = {
  title: '证书库存管理使您的工作流程自动化',
  options: [
    {
      title: '实时证书列表',
      summary: '定期的网络扫描会自动更新清单。'
    },
    {
      title: '搜索证书列表',
      summary: '输入域名即可找到特定的证书'
    },
    {
      title: '灵活的细分',
      summary: '使用不同个过滤器创建用于跟踪的自定义证书组。'
    }
  ]
}

// 特性
const features = {
  title: '证书库存管理工具特点',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '证书详情', content: ['获取有关每个SSL证书的详细信息：来源，名称，有效期，密钥和签名信息以及其他详细信息。包含：证书链详细信息、证书吊销状态、CRL分发点、权限信息访问、主题备用名称、签发者详细资料'], img: '' }, // 左
      { title: '', content: [], img: '' } // 右
    ],
    [
      { title: '证书管理', content: ['您可以上传、下载、删除证书，实现证书的跨平台统一管理'], img: '' },
      { title: '证书分析', content: ['交互式饼图以可视方式呈现证书数据，分组方式为：签名哈希算法、证书签发类型、私钥长度、证书颁发机构'], img: '' }
    ],
  ]
}

// 步骤
const step = {
  title: '3步使用证书库存管理',
  link: 'https://app.bitcert.com/sign-up', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '',
      summary: '运行外部或内部网络的发现。'
    },
    {
      img: '',
      summary: '在一个清单列表中分析检测到的证书。'
    },
    {
      img: '',
      summary: '筛选和分组证书以加快跟踪速度。'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}
