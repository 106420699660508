// 对应版本提示
const configTips = {
  modern: 'Services with clients that support TLS 1.3 and donnot need backward compatibility',
  intermediate: 'Intermediate General-purpose servers with a variety of clients, recommended for almost all systems',
  old: 'Compatible with a number of very old clients, and should be used only as a last resort'
}

export default {
  tlsConfig: {
    pageTitle: 'TLS Configuration Generator',
    title: 'TLS Configuration Generator',
    subtitle: '',
    summary: [
      'Bitcert 提供的SSL在线工具所有用户均可免费使用，用户在Bitcert 在线工具使用中、使用后产生的任何错误或造成任何损失，Bitcert 不承担任何责任，用户使用Bitcert 在线工具即表示接受本协议。',
      'Bitcert 提供的 TLS 配置生成器是离线工具，Bitcert 不会存储和收集用户私钥信息。'
    ],
    gratitude: 'Thanks to Mozilla provide such great tool!',
    form: {
      server: 'Server Software',
      config: {
        lable: 'Configuration',
        options: [
          'Modern',
          'Intermediate',
          'Old'
        ]
      },
      configTips,
      environment: {
        lable: 'Environment',
        options: [
          "Server Version",
          "OpenSSL Version"
        ]
      },
      hsts: 'HSTS'
    }
  }
}

