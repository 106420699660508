export default {
  "createCSR": {
    "pageTitle": "CSR 文件在线生成工具",
    "title": "CSR 文件在线生成工具",
    "subtitle": "",
    "summary": [
      "本工具是 CSR 在线生成工具工具、生成CSR工具、ECC CSR文件生成、SSL证书的 ECC RSA文件生成、代码签名证书的 ECC RSA文件生成，填写下表，点击生成 CSR 按钮，将会自动生成 CSR 和私钥",
      "Bitcert.com 提供的证书工具供所有用户免费使用，Bitcert 不记录任何信息，用户在使用证书工具中、使用证书工具后产生的任何错误或造成任何损失，Bitcert 不承担任何责任。"
    ]
  }
}
