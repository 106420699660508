<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-05-11 10:13:44
-->
<template>
  <router-link class="logo-link" to="/">
    <!-- <img
      :src="coverImgUrl"
      :draggable="false"
      :style="customStyle"
      class="logo"
      alt=""
      @click="goHome()"
    > -->
    数证科技
  </router-link>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'Logo',
  props: {
    src: String,
    white: Boolean,
    customStyle: String,
  },
  setup (props) {
    const coverImgUrl = computed(() => {
      const { src, white } = props
      // return src || require(`../assets/certbase-${white ? 'white' : 'theme'}-logo.svg`)
      return require(`../assets/bitcert-theme-logo.svg`)
    })
    return {
      coverImgUrl
    }
  },
  methods: {
    goHome () {
      // this.$router.push('/') // eslint-disable-line
    }
  }
}
</script>

<style lang="less" scoped>
.logo-link {
  display: inline-block;
  font-weight: 500;
  font-size: 26px;
  color: @theme-color;
}
.logo {
  max-width: 220px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
}
</style>
