const reasonsToBuy = [
  {
    title: `It's fast, simple, and convenient`,
    content: 'Our optimized systems and processes make it quick and easy to buy, install, use, manage and renew your certificates—no matter how many you need.'
  },
  {
    title: `We're here to help`,
    content: 'Your security is our top priority, which is why we offer top-notch support. If you need help with one of our products, you’ll always talk to a trained security expert.'
  },
  {
    title: `We only practice best practice`,
    content: 'Integrity is the core of trust and the foundation of our brand. Not only are our internal data systems PCI security compliant, but our business practices are WebTrust certified for: security, availability, processing integrity, confidentiality and privacy.'
  },
  {
    title: `Get the best security at a great value`,
    content: 'We offer a full range of 256-bit encryption TLS/SSL products to customers around the globe at the lowest possible cost, including EV, OV, DV, Wildcard and Multi-Domain SAN SSL certificates. Even better, our solutions support virtually every browser and popular mobile device.'
  },
  {
    title: 'Reputation matters',
    content: `Bitcert is an established, well respected security brand, powered by the world's top tier CAs like DigiCert, GlobalSign, Sectigo, Thawte, Certum, Entrust, GeoTrust, and PositiveSSL—the most trusted Certificate Authorities in the industry.`
  }
]

// try-it-30d
const tryIt30d = [
  {
    title: 'Try it for 30-days!',
    content: 'Buy it, install it and try it out. Cancel for any reason within 30-days of issuance for a full 100% refund.'
  },
  {
    title: 'Get it in just 10-minutes',
    content: 'DV certificates can be issued in just minutes. OV/EV in 2 days. Buy it today, get it today.'
  },
  {
    title: 'No paperwork required',
    content: `Certs only require you to demonstrate proof of domain-control by email, DNS, or file authentication, validation team will help you get all done.`
  },
]

// About
const about = `We're backed by 10 years of great service to companies and organizations all over the world. Our core principles are experience, knowledge and customer satisfaction.`

export default {
  nbrand: {
    brandCardTitle: `Includes a $brand Trust Seal for your site!`,
    reasonsToBuy,
    tryIt30d,
    about
  }
}