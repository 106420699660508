const banner = {
  title: '等保合规',
  btn: '立即咨询',
  summary: '针对等保2.0，Bitcert 提供等级保护合规解决方案，助力各行各业企业快速通过等保合规，一站式服务您可享受一站式等保测评，可满足网络攻击防护、数据备份、安全加密、数据审计等等等保合规各项需求，并全面提高客户安全管理还有技术能力水平。'
}
const cjnModules = [
  {
    title: '功能介绍',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      }
    ]
  },
  {
    title: '功能介绍',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      }
    ]
  },
  {
    title: '功能介绍',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      }
    ]
  },
  {
    title: '功能介绍',
    span: 12, // 不用编辑
    layout: 'full-bottom', // 不用编辑
    contents: [
      {
        icon: '/images/scene_1_1608452855.png',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/scene_1_1608452855.png',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      }
    ]
  },
]

const articles = [
  {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  },
  {
    icon: '/logo/globalsign.svg',
    layout: 'end',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  }
]

export default {
  complianceAuth: {
    banner,
    articles,
    cjnModules
  }
}