const banner = {
  title: 'SSL应用安全网关',
  btn: '立即咨询',
  summary: 'SSL应用安全网关作为应用层代理设备，提供了兼顾安全性与高性能的业务优化解决方案。SSL应用安全网关利用SSL卸载技术及负载均衡机制，在保障通讯数据安全传输的同时，减少后台应用服务器的性能消耗，并实现服务器集群的冗余高可用，大幅度提升整个业务应用系统的安全性和稳定性。此外，借助多重性能优化技术更可缩短业务访问的响应等待时间，明显提升用户的业务体验。SSL应用安全网关具备国家密码局颁发的商用密码产品型号证书，针对公共SSL算法的安全隐患问题，原生支持使用国密办SM系列算法进行SSL加密和认证，为企业和单位的敏感业务系统提供更可靠的安全加固，以满足未来的行业合规性要求。'
}
const cjnModules = [
  {
    title: '需求分析',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '安全',
        content: '数据明文传输的风险越来越高，用户需要采用HTTPS加密的方式进行数据传输，以保障应用高安全。'
      },
      {
        icon: '/images/content/ia_400000109.svg',
        title: '合规',
        content: '商用密码政策、标准、规范不断发布，尤其是2019年密码法的发布，大幅推动了密码体系的升级改造。企业应用安全需要符合国家监管，做到合规。'
      },
      {
        icon: '/images/content/ia_100000105.svg',
        title: '业务访问体验',
        content: '在保障应用高安全的同时，兼顾提升用户的业务访问响应速度。'
      }
    ]
  },
  {
    title: '技术优势与亮点',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_400000110.svg',
        title: '安全与管理结合',
        content: '对每种应用配置虚拟服务通道所需IP、端口、CA信任域及资源访问控制； 支持HTTP代理模式，为不需要加密的应用提供透明HTTP虚拟服务通道； 支持通过邮件、SNMP TRAP等途径发送系统故障告警信息。'
      },
      {
        icon: '/images/content/ia_400000111.svg',
        title: '完善的SSL卸载功能',
        content: '支持标准TLS1.0/1.1/1.2/1.3；支持多CA信任域；支持多级CA证书链； 支持SSL弱算法过滤和算法优先级选择；支持客户端证书信息透传（HTTP Header、Cookie、URL等方式）；支持设置允许客户端证书较小密钥长度； 支持错误重定向功能（错误类型自定义，低于指定加密强度等）。'
      },
      {
        icon: '/images/content/ia_400000113.svg',
        title: '可扩展服务器负载均衡功能',
        content: 'SSL安全网关在提供SSL事务卸载功能的同时，还可以扩展支持服务器负载均衡功能，保障业务应用的性能优化和安全优化； 支持HTTP压缩、连接复用、RAM高速缓存等功能，进一步释放服务器性能压力。'
      }
    ]
  }
]

export default {
  gateway: {
    banner,
    cjnModules
  }
}