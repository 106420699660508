
// 对应版本提示
const configTips = {
  modern: '支持TLS 1.3且不向下兼容',
  intermediate: '支持常见的客户端（推荐使用）',
  old: '该方案兼容比较老的设备'
}

export default {
  tlsConfig: {
    pageTitle: 'SSL/TLS 证书服务器安装配置生成器',
    title: 'SSL/TLS 证书服务器安装配置生成器',
    subtitle: '',
    summary: [
      "该工具可生成多种不同服务器环境的 SSL/TLS 证书配置文件，以帮助您遵循Mozilla服务器端TLS配置准则。",
      "感谢 Mozilla 提供服务器端TLS配置准则和配置信息。"
    ],
    gratitude: '感谢 Mozilla 提供的相关技术支持',
    form: {
      server: '服务器名称',
      config: {
        lable: '配置类型',
        options: [
          '新版支持',
          '强兼容性',
          '老版本'
        ]
      },
      configTips,
      environment: {
        lable: '版本参数',
        options: [
          '服务器版本',
          'OpenSSL 版本'
        ]
      },
      hsts: '强制跳转'
    }
  }
}
