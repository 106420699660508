<template>
  <ul class="grade">
    <li
      v-for="item in count"
      :key="`grade-item-${item}`"
      class="grade-item">
      <img :src="imgUrl(item)" alt="" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Grade',
  props: {
    count: { // 等级基数
      type: Number,
      default: 3
    },
    grade: {
      type: Number,
      default: 1
    }
  },
  methods: {
    imgUrl (currentIndex) {
      return this.grade >= currentIndex ? require('@/assets/images/start-active.svg') : require('@/assets/images/start.svg')
    }
  }
}
</script>

<style lang="less" scoped>
.grade {
  display: flex;
  align-items: center;
  justify-content: center;
}
.grade-item {
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
