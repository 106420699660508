// 代理
// path: https://bitcert.com/partner/agent


export default {
  pageTitle: '合作伙伴',
  title: '数证科技数字证书合作伙伴计划',
  subtitle: '对于希望与数证科技合作为客户提供高可信数字证书的企业而言，它是完美的选择',
  contents: [
    { // 1
      title: '',
      content: [
        '数证科技本着合作共赢的经营理念，与合作商真诚合作，不仅可以携手缔造佳绩，还可以共享信息安全领域前沿的技术与经验。数证科技作为多家 CA 机构合作伙伴，在SSL/TLS证书管理、发现、监控和审计上提供快速、安全的批量解决方案。我们将全面支持合作商的业务拓展，不仅按需给到您空前的产品折扣，并且为您及时解决市场反馈的问题和提供专业的行业解决方案。',
        '数证科技是全球 SSL/TLS 证书的大型发行商之一，隶属于成都数证科技有限公司。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福，数证科技在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。'
      ]
    },
    { // 2
      title: '谁应该加入数证科技证书合作伙伴计划',
      content: [
        '合作伙伴计划主要面向电子商务建站公司、互联网服务商、服务器提供商、域名注册商和专业从事数字证书销售的服务商家提供可靠、简单、快速的合作方式，帮助他们通过数证科技来扩充产品线, 向其用户提供适用于各行业的数字证书产品。',
        '想咨询业务或者了解更多合作伙伴计划，请联系在线客服。'
      ]
    },
    { // 4
      title: '合作伙伴计划可以为我做什么？',
      content: [
        '我们的产品覆盖面非常广泛, 同时具有丰厚的利润优势, 我们已经同部分优质的合作伙伴建立了合作关系。成为合作商，您就可以享受更优惠的价格，为您公司节省大量的成本，为您的业务获得更多利润。',
      ]
    }
  ],
  lists: [
    { // 3
      // 列表
      title: '希望批量购买证书的任何人都可以利用此计划提供的大量批发折扣:',
      content: [
        '虚拟主机提供商 可能会发现自己在管理 SSL/TLS 证书针对许多客户（具有广泛的需求和技术技能）。',
        '公司和组织 可能需要购买 S/MIME，客户端身份验证和文档签名证书和代码签名证书为大批量员工使用。',
        '系统集成服务商 可能要负责保护整个企业网络中的数百台服务器。',
        '域名注册商 不妨提供 SSL/TLS 证书 以及他们的主要产品。',
        '互联网上就可以数证科技提供的各种公共信任的数字证书来扩展其产品线。',
        '总之，任何需要批量购买证书的数证科技客户可以从本计划的批发价中受益！'
      ]
    },
    { // 5
      // 列表
      title: '',
      content: [
        '优惠的合作商价格。',
        '灵活的销售支持。',
        '专业的技术支持服务。',
        '免费开户，免服务费。',
        '灵活的帐户充值模式'
      ]
    }
  ]
}
