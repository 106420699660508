// 联盟会员计划
// path: https://bitcert.com/partner/main


export default {
  pageTitle: '联盟会员计划',
  title: 'Bitcert 联盟会员计划',
  subtitle: '通过您的博客、网站、电子邮件或微信、微博等社交媒体赚取额外收入！',
  contents: [
    {
      title: '',
      content: [
        'Bitcert 推广联盟计划是一个很好的赚钱机会，允许您与全球知名的 SSL 数字证书服务商合作赚取高额佣金。我们将为您提供所需的一切，包括静 /动态横幅广告，文字链接和产品链接。该过程简单且自动化。您负责流量推广，我们负责其余的事情。',
        'Bitcert 是全球 SSL/TLS 证书的大型发行商之一，隶属于成都数证科技有限公司。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福，Bitcert 在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供便宜的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。'
      ]
    },
    {
      title: '什么是推广联盟？',
      content: [
        '数证科技（Bitcert.com）推广联盟向网站所有者支付通过 Bitcert.com 推广链接成功购买产品的每位客户的佣金。',
      ]
    },
    {
      title: '加入需要多少费用？',
      content: [
        '加入 Bitcert.com 的推广联盟是免费的！',
      ]
    },
    {
      title: '我应该卖什么产品？',
      content: [
        '立即开始销售Sectigo、GlobalSign、DigiCert、GeoTrust、Thawte、RapidSSL、AlphaSSL、Entrust等品牌数字证书的产品和服务。',
      ]
    },
    {
      title: '我应该在哪里放置广告？',
      content: [
        '尝试在您拥有大量访问者的区域投放广告。请记住，放置和促销越多，您的网站将获得的收入就越多！',
      ]
    },
    {
      title: '我可以每天监控我的佣金吗？',
      content: [
        '是的，您可以随时登录控制面板查看和跟踪您的佣金。',
      ]
    },
    {
      title: '我可以使用哪种类型的链接和优惠？',
      content: [
        '我们为您提供各种类型的链接。利用您的创造力和您对访问您网站的人的了解，选择具吸引力且有可能吸引点击次数的链接类型。您的链接导致我们网站上的销售越多，您的网站将获得的收入就越多。',
        '您可以以横幅、文字、个别产品、搜索框和店面形式创建指向我们网站的链接。您可以选择使用我们提供的一个或多个链接，也可以创建自己的链接。'
      ]
    }
  ]
}
